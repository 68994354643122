import React, {Component} from 'react';

export default class NullHighLightText extends Component {
    valueClass(value) {
        if (value === '' || value === null) {
            return 'text-danger';
        } else {
            return '';
        }
    }

    formatValue(value) {
        if (this.props.format === 'date') {
            return this.props.value ? new Intl.DateTimeFormat('en-GB', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit'
            }).format(new Date(this.props.value)) : 'Never'
        } else {
            return this.props.value ? this.props.value : 'Unknown';
        }
    }

    render() {
        return (
            <span className={this.valueClass(this.props.value)}>
                {this.formatValue(this.props.value)}
            </span>
        )

    }
}
import {
    AGGREGATED_TIMESERIES_FETCHING,
    AGGREGATED_TIMESERIES_FETCHING_SUCCESS,
    DEVICE_SELECTED,
    DEVICE_TIMESERIES_FETCHING,
    DEVICE_TIMESERIES_FETCHING_SUCCESS
} from "../constants/action-types";
import {get} from "./index";

export function selectDevice(device_id) {
    return async function (dispatch, getState) {
        if (getState().devices.selected && getState().devices.selected.device_id === device_id) {
            return
        }
        let devices = getState().devices.items;
        let device = null;
        if (devices.length === 0) {
            device = await get(`/device/${device_id}`);
        } else {
            device = devices.find((el) => {
                return el.device_id === device_id
            });
        }
        dispatch({type: DEVICE_SELECTED, payload: device});
        return dispatch(loadDeviceTimeSeriesData());
    }
}


export function loadDeviceTimeSeriesData() {
    return async function (dispatch, getState) {
        dispatch({type: DEVICE_TIMESERIES_FETCHING});
        const device = getState().devices.selected;
        if (device) {
            console.log(device);
            return get(`/device/${device.device_id}/timeseries`).then(
                (data) => {
                    console.log(data);
                    if (data) {
                        data.times = data.times.map(d => new Date(d).getTime());
                    }
                    return dispatch({
                        type: DEVICE_TIMESERIES_FETCHING_SUCCESS,
                        payload: data
                    })
                });
        }


    }
}

export function loadAggegatedTimeSeriesData(gateway_id, parameter) {
    return async function (dispatch, getState) {
        dispatch({type: AGGREGATED_TIMESERIES_FETCHING});
        return get(`/report/timeseries?gateway_id=${gateway_id}&parameter=${parameter}`).then(
            (data) => {
                let processedData = {};

                processedData.times = data.times;
                processedData.devices = [];
                data.columns.forEach((el, index) => {
                    el.data = [];
                    data.data.forEach((d_el) => {
                        el.data.push(d_el[index])
                    });
                    processedData.devices.push(el);
                });

                return dispatch({
                    type: AGGREGATED_TIMESERIES_FETCHING_SUCCESS,
                    payload: processedData
                })
            }
        )
    }
}
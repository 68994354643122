import {combineReducers} from "redux";
import {authReducer as auth} from "./auth";
import {profileReducer as profile} from "./profile";
import {gatewaysReducer as gateways} from "./gateway";
import {devicesReducer as devices} from "./devices";
import {gatewayFilesReducer as gatewayFiles} from "./gateway_files";
import {deviceTimeSeriesReducer as deviceTimeSeries} from "./deviceTimeSeries"
import {aggregatedTimeSeriesReducer as aggregatedTimeSeries} from "./aggregatedTimeSeries";
import {companiesReducer as companies} from "./companies";


export default combineReducers({
    auth,
    profile,
    companies,
    gateways,
    devices,
    gatewayFiles,
    deviceTimeSeries,
    aggregatedTimeSeries
});

// file: /components/LinkButton.jsx
import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import {connect} from "react-redux";

const LinkButton = (props) => {
    console.log(props);
    const {
        history,
        location,
        match,
        staticContext,
        onClick,
        // ⬆ filtering out props that `button` doesn’t know what to do with.

    } = props;
    const {
        to,
        company,
        ...rest
    } = props.ownProps;

    console.log(to);
    let theTo = to;
    if (company && props.selectedCompany) {
        theTo = theTo + `#company=${props.selectedCompany.company_id}`
    }
    console.log(theTo);
    return (
        <button className={'btn btn-primary'}
            {...rest} // `children` is just another prop!
            onClick={(event) => {
                onClick && onClick(event);
                history.push(theTo)
            }}
        />
    )
};

LinkButton.propTypes = {
    to: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    company: PropTypes.bool
};

const mapStateToProps = function(state, ownProps) {
    return {
        selectedCompany: state.companies.selected,
        ownProps: ownProps
    }
};
export default connect(mapStateToProps)(withRouter(LinkButton));
import React, {Component} from "react";
import "./PageSpinner.scss"
import {connect} from "react-redux";

class PageSpinner extends Component {


    render() {
        return (this.props.loading ? (<div className="spinner">
            <ul className="bokeh">
                <li/>
                <li/>
                <li/>
                <li/>
            </ul>
        </div>) : (null))
    }
}


const mapStateToProps = state => {
    return {
        loading: (
            state.companies.loading ||
            state.gateways.loading ||
            state.profile.loading ||
            state.auth.authenticating ||
            state.gatewayFiles.loading ||
            state.devices.loading ||
            state.deviceTimeSeries.loading ||
            state.aggregatedTimeSeries.loading
        )
    };
};
export default connect(mapStateToProps)(PageSpinner);

import {
    COMPANY_SELECTED,
    DEVICE_TIMESERIES_FETCHING,
    DEVICE_TIMESERIES_FETCHING_SUCCESS,
    LOGOUT
} from "../constants/action-types";

const initialState = {
    data: null,
    loading: false,
};

export function deviceTimeSeriesReducer(state = initialState, action:any):any {

    switch (action.type) {

        case DEVICE_TIMESERIES_FETCHING:
            return {
                ...state,
                loading: true,
                data: null,
            };


        case DEVICE_TIMESERIES_FETCHING_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
            };

        case COMPANY_SELECTED:
        case LOGOUT:
            return {
                ...state,
                data: null,
                loading: false,
            };

        default:
            return state;

    }
}
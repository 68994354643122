import React, {Component} from "react";
import Pagination from "../../components/Pagination";
import connect from "react-redux/es/connect/connect";
import Moment from "react-moment";
import {loadAggegatedTimeSeriesData} from "../../actions/device";
import {Table} from "reactstrap";
import CompanyNavLink from "../../components/navigation/CompanyNavLink";

class GatewayReport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pageOfDevices: [],
        };

        this.onChangePage = this.onChangePage.bind(this);
    }

    componentWillMount = async () => {
        await this.props.loadAggegatedTimeSeriesData(this.props.gateway_id, 'Energy');
    };

    onChangePage(pageOfDevices) {
        this.setState({pageOfDevices: pageOfDevices});
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if (this.props.reportData !== nextProps.reportData) {
            this.setState({pageOfDevices: []})
        }
    }


    render() {
        return (
            this.props.reportData ?
                <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                    <tr>
                        <th className="text-left">Device ID</th>
                        <th className="text-left">Serial Number</th>
                        <th className="text-left">Type</th>
                        <th className="text-left">Manufacturer</th>
                        {
                            this.props.reportData.times.map((value, index) => {
                                return (
                                    <th className="text-left" key={index}>
                                        <Moment format="DD/MM/YYYY" date={value}/>
                                    </th>
                                )
                            })
                        }
                    </tr>
                    </thead>

                    {this.state.pageOfDevices.map((value, index) => {
                        return (
                            <tbody key={index} className={'list'}>
                            <tr>
                                <td className="text-left">
                                    <CompanyNavLink to={`/admin/device/${value.device_id}`}>
                                        {value.device_id}
                                    </CompanyNavLink>
                                </td>
                                <td className="text-left">{value.serial_number}</td>
                                <td className="text-left">{value.device_type}</td>
                                <td className="text-left">{value.manufacturer}</td>
                                {
                                    value.data.map((d, i) => {
                                        return (
                                            <td key={index * 1000 + i}>{
                                                d ? d : '-'
                                            }</td>
                                        )
                                    })
                                }
                            </tr>
                            </tbody>
                        )
                    })
                    }
                    <tbody>
                    <tr>
                        <td colSpan="5">
                            <Pagination items={this.props.reportData.devices} onChangePage={this.onChangePage}/>
                        </td>
                    </tr>
                    </tbody>

                </Table>
                : null

        )
    }
}


const mapStateToProps = state => {
    return {
        reportData: state.aggregatedTimeSeries.data,
        loading: state.aggregatedTimeSeries.loading,
    };
};

export default connect(mapStateToProps, {loadAggegatedTimeSeriesData})(GatewayReport);

import React, {Component} from "react";
import {Route, Switch, withRouter} from "react-router-dom";
import CompanyList from "./CompanyList";


class CompanyPage extends Component {

    render() {
        return <Switch>
            <Route exact path="/admin/company" component={CompanyList}/>
        </Switch>
    }
}

export default withRouter(CompanyPage)
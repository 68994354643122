import React, {Component} from 'react';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";


export default class SingleChart extends Component {


    render() {
        let options = {
            title: {
                text: ''
            },
            xAxis: {
              type: 'datetime'
            },
            series: [{
                data: this.props.data
            }],
            legend: {
                enabled: false,
            },
            options: {
                legend: false
            }

        };
        console.log(options);
        return (

            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        )

    }

}
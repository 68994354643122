import * as queryString from "query-string";


export function identifyReferrer(props) {
    let qs = identifyReferrerFromQueryString(props);
    if (qs) {
        return qs
    } else {
        return identifyReferrerFromPathname(props);
    }
}

export function identifyReferrerFromQueryString(props) {
    const values = queryString.parse(props.location.search);
    if (values.referrer) {
        return values.referrer;
    } else {
        return null
    }
}

export function identifyReferrerFromPathname(props) {
    let ref = props.location.pathname;
    if (ref.startsWith('/auth/login') || ref === '/') {
        return null
    } else {
        return ref
    }
}
export default {
    apiGateway: {
        REGION: process.env.REACT_APP_REGION,
        URL: process.env.REACT_APP_API_URL
    },
    cognito: {
        REGION: process.env.REACT_APP_REGION,
        USER_POOL_ID: process.env.REACT_APP_COGNITO_POOL,
        APP_CLIENT_ID: process.env.REACT_APP_COGNITO_CLIENT,
    }
};
import React, {Component} from "react";
import CreateGatewayForm from "./CreateGatewayForm";
import NullHighLightText from "../../components/NullHighLightText";
import {connect} from "react-redux";
import {deleteGateway, loadGateways} from "../../actions/gateway";
import {Card, CardHeader, Container, Media, Row, Table} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader";
import GatewayDetailButton from "./GatewayDetailButton.jsx";
import GatewayDeleteButton from "./GatewayDeleteButton";
import CompanyNavLink from "../../components/navigation/CompanyNavLink";


class GatewayList extends Component {

    componentDidMount = async () => {
        await this.props.loadGateways();
    };

    render() {
        return (
            <>
                <SimpleHeader name="List" parentName="Gateways"/>
                <Container className="mt--6" fluid>
                    <Row>
                        <div className="col">
                            <Card className="bg-default shadow">
                                <CardHeader className="bg-transparent border-0">
                                    <h3 className="text-white mb-0">All gateways</h3>
                                </CardHeader>
                                <Table
                                    className="align-items-center table-dark table-flush"
                                    responsive
                                >
                                    <thead className="thead-dark">

                                    <tr>
                                        <th scope="col" className="text-left">Id</th>
                                        <th scope="col" className="text-left">Serial</th>
                                        <th scope="col" className="text-left">Location</th>
                                        <th scope="col" className="text-left">Last Contact</th>
                                        <th scope="col" className="text-left">&nbsp;</th>
                                    </tr>
                                    </thead>
                                    {
                                        this.props.gateways.length > 0 ? (
                                                this.props.gateways.map((value, index) => {
                                                    return (
                                                        <tbody key={index} className={'list'}>
                                                        <tr>
                                                            <th scope={"row"}>
                                                                <Media className="align-items-center">
                                                                    <a
                                                                        className="avatar rounded-circle mr-3"
                                                                        href="#"
                                                                        onClick={e => e.preventDefault()}
                                                                    >
                                                                        <img
                                                                            alt="..."
                                                                            src="/elvaco.jpeg"
                                                                        />
                                                                    </a>
                                                                    <Media>
                                                                        <CompanyNavLink
                                                                            to={`/admin/gateway/${value.gateway_id}`}>
                                                        <span className="name mb-0 text-sm">
                                                          {value.gateway_id}
                                                        </span>
                                                                        </CompanyNavLink>
                                                                    </Media>
                                                                </Media>
                                                            </th>


                                                            <td>{value.serial_number}</td>
                                                            <td>{value.location}</td>
                                                            <td>
                                                                <NullHighLightText value={value.last_contact}
                                                                                   format='date'/>
                                                            </td>
                                                            <td className="table-actions">
                                                                <GatewayDetailButton gateway={value}/>
                                                                <GatewayDeleteButton gateway={value}/>

                                                            </td>
                                                        </tr>
                                                        {/*<tr>*/}
                                                        {/*<td colSpan="4" className="pb-1 pt-0 text-left" style={{'borderTop': 0}}>*/}
                                                        {/*<small*/}
                                                        {/*className="px-1"> Model: <NullHighLightText value={value.model}/>*/}
                                                        {/*</small>*/}
                                                        {/*<small*/}
                                                        {/*className="px-1">Firmware: <NullHighLightText*/}
                                                        {/*value={value.firmware_version}/></small>*/}
                                                        {/*<small*/}
                                                        {/*className="px-1">IP: <NullHighLightText value={value.ip_address}/>*/}
                                                        {/*</small>*/}
                                                        {/*<small*/}
                                                        {/*className="px-1">MAC: <NullHighLightText value={value.mac_address}/>*/}
                                                        {/*</small>*/}
                                                        {/*<small*/}
                                                        {/*className="px-1">Username: {value.credentials.username}</small>*/}
                                                        {/*<small*/}
                                                        {/*className="px-1">Password:{value.credentials.password}</small>*/}
                                                        {/*<small className={'px-1'}>URL: {Config.apiGateway.URL}/integrations/elvaco/{value.company_id}</small>*/}
                                                        {/*</td>*/}
                                                        {/*</tr>*/}

                                                        </tbody>
                                                    )
                                                })
                                            )
                                            : (
                                                <tbody>
                                                <tr>
                                                    <td colSpan="6">
                                                        No Gateways
                                                    </td>
                                                </tr>
                                                </tbody>
                                            )}
                                    <tbody>
                                    <tr>
                                        <td colSpan="6" className="align-right">
                                            <CreateGatewayForm/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        gateways: state.gateways.items,
        loading: state.gateways.loading
    };
};

export default connect(mapStateToProps, {deleteGateway, loadGateways})(GatewayList);

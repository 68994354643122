import {Auth} from "aws-amplify";

import {
    AUTHENTICATING,
    AUTHENTICATING_ERROR,
    AUTHENTICATING_SUCCESS,
    LOGOUT,
    PROFILE_FETCHING,
    PROFILE_FETCHING_SUCCESS
} from "../constants/action-types";
import {get} from "./index";
import {loadCompanies} from "./companies";


export function loadProfile(company_id = null) {
    return async function (dispatch) {
        dispatch({type: PROFILE_FETCHING});
        get('/user/profile')
            .then((profile) => {
                if (profile.type === 'admin') {
                    dispatch(loadCompanies(company_id === null, company_id)).then(
                        () => {
                            dispatch({type: PROFILE_FETCHING_SUCCESS, payload: profile});
                        }
                    );
                } else {
                    dispatch({type: PROFILE_FETCHING_SUCCESS, payload: profile});
                }
            })
    }
}

export function authenticate(username, password) {
    return async function (dispatch) {
        dispatch({type: AUTHENTICATING});
        return Auth.signIn(username, password)
            .then(() => {
                    dispatch(loadProfile())
                        .then(() => {
                                dispatch({type: AUTHENTICATING_SUCCESS})
                            }
                        )
                }
            )
            .catch((err) => {
                dispatch({type: AUTHENTICATING_ERROR, payload: err})
            });
    }
}

export function logout() {
    return async function (dispatch) {
        return Auth.signOut()
            .then(() => {
                    dispatch({type: LOGOUT})
                }
            )
    }
}

export function silentAuth(company_id) {
    return async function (dispatch) {
        return new Promise((resolve, reject) => {
            dispatch({type: AUTHENTICATING});
            Auth.currentAuthenticatedUser()
                .then(() => {
                        dispatch(loadProfile(company_id))
                            .then(() => {
                                    resolve(dispatch({type: AUTHENTICATING_SUCCESS}))
                                }
                            )
                    }
                )
                .catch((err) => {
                    dispatch({type: AUTHENTICATING_ERROR, payload: err});
                    reject(err);
                });

        });
    }
}
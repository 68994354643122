import React, {Component} from "react";
import {Route, Switch, withRouter} from "react-router-dom";
import GatewayList from "./GatewayList";
import GatewayHomepage from "./GatewayHomepage";


class GatewayPage extends Component {

    render() {
        return <Switch>
            <Route exact path="/admin/gateway" component={GatewayList}/>
            <Route path="/admin/gateway/:gateway_id" component={GatewayHomepage}/>
        </Switch>
    }
}

export default withRouter(GatewayPage)
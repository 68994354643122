import {
    GATEWAY_CREATING,
    GATEWAY_CREATING_SUCCESS,
    GATEWAY_DELETING,
    GATEWAY_DELETING_SUCCESS,
    GATEWAY_DEVICES_FETCHING,
    GATEWAY_DEVICES_FETCHING_SUCCESS,
    GATEWAY_FILES_FETCHING,
    GATEWAY_FILES_FETCHING_SUCCESS,
    GATEWAY_SELECTED,
    GATEWAYS_FETCHING,
    GATEWAYS_FETCHING_SUCCESS
} from "../constants/action-types";
import {del, get, post} from "./index";

function getCompanySelector(state) {
    if (state.companies.selected) {
        return `?company_id=${state.companies.selected.company_id}`;
    } else {
        return '';
    }
}


export function loadGateways() {
    return async function (dispatch, getState) {
        dispatch({type: GATEWAYS_FETCHING});

        let gateways = await get('/gateway' + getCompanySelector(getState()))
            .then(
                (data) => (data.gateways))
            .catch((err) => {
                console.log('error');
                return []
            })
        ;
        gateways = gateways.sort((a, b) => {
            if (a.serial_number < b.serial_number) return -1;
            if (a.serial_number > b.serial_number) return 1;
            return 0;
        });
        return dispatch({type: GATEWAYS_FETCHING_SUCCESS, payload: gateways});
    }
}


export function selectGateway(gateway_id) {
    return async function (dispatch, getState) {
        if (getState().gateways.selected && getState().gateways.selected.gateway_id === gateway_id) {
            return
        }
        let gateways = getState().gateways.items;
        if (gateways.length === 0) {
            await dispatch(loadGateways());
            gateways = getState().gateways.items;
        }
        const gateway = gateways.find((el) => {
            return el.gateway_id === gateway_id
        });
        if (gateway) {
            dispatch({type: GATEWAY_SELECTED, payload: gateway});
            dispatch(loadGatewayDevices(gateway_id));
            dispatch(loadGatewayFiles(gateway_id));
        }
    }
}

export function createGateway(gateway) {
    return async function (dispatch) {
        dispatch({type: GATEWAY_CREATING});
        return post('/gateway', gateway).then(
            (data) => {
                dispatch({type: GATEWAY_CREATING_SUCCESS, payload: data});
            }
        )
    }
}

export function deleteGateway(gateway_id) {
    return async function (dispatch) {
        dispatch({type: GATEWAY_DELETING});
        return del(`/gateway/${gateway_id}`).then(
            (data) => {
                dispatch({type: GATEWAY_DELETING_SUCCESS, payload: gateway_id});
            }
        ).then(() => {
            return dispatch(loadGateways())
        })
    }
}

export function loadGatewayDevices(gateway_id) {
    return async function (dispatch) {
        dispatch({type: GATEWAY_DEVICES_FETCHING});
        let devices = await get(`/gateway/${gateway_id}/device`).then(
            (data) => (data.items)
        );
        devices = devices.sort((a, b) => {
            if (a.serial_number < b.serial_number) return -1;
            if (a.serial_number > b.serial_number) return 1;
            return 0;
        });
        return dispatch({type: GATEWAY_DEVICES_FETCHING_SUCCESS, payload: devices});
    }
}

export function loadGatewayFiles(gateway_id) {
    return async function (dispatch) {
        dispatch({type: GATEWAY_FILES_FETCHING});
        const files = await get(`/gateway/${gateway_id}/files`).then(
            (data) => (data.files)
        );
        return dispatch({type: GATEWAY_FILES_FETCHING_SUCCESS, payload: files});
    }
}
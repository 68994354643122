import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import {withRouter} from "react-router-dom";
import {selectDevice} from "../../actions/device";
import SingleChart from "../../components/charts/SingleChart";
import SimpleHeader from "components/Headers/SimpleHeader";
import {Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";


class DeviceHomepage extends Component {

    async componentWillMount() {
        console.log(this.props.match);
        await this.props.selectDevice(this.props.match.params.device_id);
    }


    render() {

        return (
            this.props.device ?
                <>
                    <SimpleHeader name={`Device ${this.props.device.device_id.substr(this.props.device.device_id.length - 8)}`} parentName="Devices"/>
                    <Container className="mt--6" fluid>

                        <Row>

                            {
                                this.props.timeseries ?
                                    (
                                        this.props.timeseries.columns.map((value, index) => {
                                                return (
                                                    <Col xl="12">
                                                        <Card>
                                                            <CardHeader>
                                                                <h6 className="surtitle">Overview</h6>
                                                                <h5 className="h3 mb-0">{value.name}</h5>
                                                            </CardHeader>
                                                            <CardBody>
                                                                <div className="chart">
                                                                    <SingleChart xaxis={this.props.timeseries.times}
                                                                                 data={this.props.timeseries.data.map(
                                                                                     (ts_value, ts_index) => {
                                                                                         return [
                                                                                             this.props.timeseries.times[ts_index],
                                                                                             parseFloat(ts_value[index])]
                                                                                     }
                                                                                 )}
                                                                                 spec={value}

                                                                    />
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                )

                                            }
                                        )
                                    ) : null
                            }
                        </Row>
                    </Container>
                </>
                :
                null
        )
    }
}

const mapStateToProps = state => {
    return {
        device: state.devices.selected,
        timeseries: state.deviceTimeSeries.data,
        loading: (state.deviceTimeSeries.loading || state.devices.loading),
    };
};

export default connect(mapStateToProps, {selectDevice})(withRouter(DeviceHomepage));

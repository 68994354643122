import React, {Component} from "react";
import PrettyBytes from "pretty-bytes";

import {API, Auth} from "aws-amplify";
import Pagination from "../../components/Pagination";
import connect from "react-redux/es/connect/connect";
import {Media, Table, UncontrolledTooltip} from "reactstrap";

class GatewayFilelist extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pageOfFiles: []
        };

        this.onChangePage = this.onChangePage.bind(this);
    }

    onChangePage(pageOfFiles) {
        this.setState({pageOfFiles: pageOfFiles});
    }

    handleClick(event) {
        console.log(event);
        this.loadFileFromApi(event.gateway_id, event.link);
    }

    async loadFileFromApi(gateway_id, link) {
        API.get('management', `/gateway/${gateway_id}/files/${link}`, await this._getHeaders()).then(
            (data) => {
                console.log(data);
                window.open(data, "_blank");
            }
        )
    }

    async _getHeaders() {
        return {
            headers: {Authorization: `Bearer ${(await Auth.currentSession()).idToken.jwtToken}`},
            'responseType': 'text'
        };
    }

    render() {
        return (
            <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                <tr>
                    <th className="text-left">&nbsp;</th>
                    <th className="text-left">Filename</th>
                    <th className="text-left">Date received</th>
                    <th className="text-left">File size</th>
                    <th className="text-left">&nbsp;</th>
                </tr>
                </thead>

                {this.state.pageOfFiles.map((value, index) => {
                    return (
                        <tbody key={index} className="list">
                        <tr>
                            <th scope="row">
                                <Media className="align-items-center">
                                    <i className="fas fa-file-excel fa-2x" style={{color: 'rgb(29, 111, 66)'}}/>
                                </Media>
                            </th>
                            <td className="text-left align-middle">
                                {value.filename}</td>
                            <td className="text-left  align-middle">
                                {new Intl.DateTimeFormat('en-GB', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: '2-digit',
                                    hour: '2-digit',
                                    minute: '2-digit'
                                }).format(new Date(value.created))}
                            </td>
                            <td className="text-left  align-middle">
                                {PrettyBytes(value.size)}
                            </td>
                            <td className="table-actions">
                                <a
                                    className="table-action"
                                    href="#"
                                    id={`tooltip-gateway-edit-${index}`}
                                    onClick={() => this.handleClick(value)}
                                >
                                    <i className="fas fa-download"/>
                                </a>
                                <UncontrolledTooltip delay={0}
                                                     target={`tooltip-gateway-edit-${index}`}>
                                    Download raw file
                                </UncontrolledTooltip>
                            </td>
                        </tr>
                        </tbody>
                    )
                })
                }
                <tbody>
                <tr>
                    <td colSpan="5">
                        <Pagination items={this.props.files} onChangePage={this.onChangePage}/>
                    </td>
                </tr>
                </tbody>
            </Table>

        )
    }
}

const mapStateToProps = state => {
    return {
        gateway: state.gateways.selected,
        loading: state.gatewayFiles.loading,
        files: state.gatewayFiles.items,
    };
};

export default connect(mapStateToProps, {})(GatewayFilelist);

import React from 'react'
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

interface Props {
    buttonLabel: string,
    title: string,
    body: string,
    className?: string,
    actionButtonColor?: string,
    show: boolean,
    handleClose: Function,
    handleAction: Function

}


export default class GatewayDeleteModal extends React.Component<Props> {

    render() {
        return <>
            <Modal isOpen={this.props.show}
                   toggle={() => {
                       this.props.handleClose()
                   }}
                   className={this.props.className}
            >
                <ModalHeader toggle={() => {
                    this.props.handleClose()
                }}>
                    {this.props.title}
                </ModalHeader>
                <ModalBody>
                    {this.props.body}
                </ModalBody>
                <ModalFooter>
                    <Button
                        color={this.props.actionButtonColor ?
                            this.props.actionButtonColor : 'primary'
                        }
                        onClick={() => {
                            this.props.handleAction()
                        }}
                    >
                        {this.props.buttonLabel}
                    </Button>
                    <Button
                        color="secondary"
                        onClick={() => {
                            this.props.handleClose()
                        }}
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    }
}


import React from 'react'
import {UncontrolledTooltip} from "reactstrap";
import {connect} from "react-redux";
import {deleteGateway} from "../../actions/gateway";
import GatewayDeleteModal from "./GatewayDeleteModal";

interface Props {
    gateway: any,
    deleteGateway: (gateway_id: string) => Promise<void>
}

interface State {
    modal: boolean
}


class GatewayDeleteButton extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            modal: false
        };
        this.toggle = this.toggle.bind(this);
        this.deleteGateway = this.deleteGateway.bind(this);
    }

    deleteGateway() {
        console.log('delete');
        this.props.deleteGateway(this.props.gateway.gateway_id);
    }

    toggle() {
        this.setState(
            {modal: !this.state.modal}
        )
    }


    render() {
        return <>
            <GatewayDeleteModal buttonLabel='Delete'
                                body={`Are you sure you want to delete gateway ${this.props.gateway.gateway_id}.
                                This action can not be undone.`}
                                title={'Delete Gateway'}
                                handleClose={this.toggle}
                                handleAction={this.deleteGateway}
                                show={this.state.modal}
                                actionButtonColor={'danger'}
            />
            <a
                className="table-action table-action-delete text-danger"
                href="#"
                id={`tooltip-gateway-delete-${this.props.gateway.gateway_id}`}
                onClick={() => {
                    this.toggle()
                }}
            >
                <i className="fas fa-trash"/>
            </a>
            < UncontrolledTooltip
                delay={0}
                target={`tooltip-gateway-delete-${this.props.gateway.gateway_id}`
                }>
                Delete
                gateway
            </UncontrolledTooltip>
        </>
    }
}

export default connect(null, {deleteGateway})(GatewayDeleteButton);

import {LOGOUT, PROFILE_FETCHING, PROFILE_FETCHING_SUCCESS} from "../constants/action-types";

const initialState = {
    user: null,
    loading: false,
};

export function profileReducer(state = initialState, action:any):any {

    switch (action.type) {

        /// Profile
        case PROFILE_FETCHING_SUCCESS:
            return {
                ...state,
                user: action.payload,
                loading: false
            };

        case PROFILE_FETCHING:
            return {
                ...state,
                user: null,
                loading: true
            };


        case LOGOUT:
            return {
                ...state,
                user: null,
            };

        default:
            return state;

    }
}
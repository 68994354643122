import React from 'react';
import PropTypes from 'prop-types';
import Pagination from "react-bootstrap/Pagination";
import './Pagination.css';

const propTypes = {
    items: PropTypes.array.isRequired,
    onChangePage: PropTypes.func.isRequired,
    initialPage: PropTypes.number,
    pageSize: PropTypes.number
};

const defaultProps = {
    initialPage: 1,
    pageSize: 10
};

class AppPagination extends React.Component {
    constructor(props) {
        super(props);
        this.state = {pager: {}};
    }

    componentWillMount() {
        // set page if items array isn't empty
        if (this.props.items && this.props.items.length) {
            this.setPage(this.props.initialPage);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // reset page if items array has changed
        if (this.props.items !== prevProps.items) {
            console.log('reset');
            this.setPage(this.props.initialPage);
        }
    }

    setPage(page) {
        console.log(`set page ${page}`);
        let {items, pageSize} = this.props;
        let pager = this.state.pager;

        if (page < 1 || page > pager.totalPages) {
            return;
        }

        // get new pager object for specified page
        pager = this.getPager(items.length, page, pageSize);

        // get new page of items from items array
        let pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1);

        // update state
        this.setState({pager: pager});

        // call change page function in parent component
        this.props.onChangePage(pageOfItems);
    }

    getPager(totalItems, currentPage, pageSize) {
        // default to first page
        currentPage = currentPage || 1;

        // default page size is 10
        pageSize = pageSize || 10;

        // calculate total pages
        let totalPages = Math.ceil(totalItems / pageSize);

        let startPage, endPage;
        if (totalPages <= 10) {
            // less than 10 total pages so show all
            startPage = 1;
            endPage = totalPages;
        } else {
            // more than 10 total pages so calculate start and end pages
            if (currentPage <= 6) {
                startPage = 1;
                endPage = 10;
            } else if (currentPage + 4 >= totalPages) {
                startPage = totalPages - 9;
                endPage = totalPages;
            } else {
                startPage = currentPage - 5;
                endPage = currentPage + 4;
            }
        }

        // calculate start and end item indexes
        let startIndex = (currentPage - 1) * pageSize;
        let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

        // create an array of pages to ng-repeat in the pager control
        let pages = [...Array((endPage + 1) - startPage).keys()].map(i => startPage + i);

        // return object with all pager properties required by the view
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
        };
    }

    render() {
        let pager = this.state.pager;

        if (!pager.pages || pager.pages.length <= 1) {
            // don't display pager if there is only 1 page
            return null;
        }

        return (
            <Pagination className="justify-content-center pagination-lg">
                <Pagination.First onClick={() => this.setPage(1)}
                                  disabled={pager.currentPage === 1}/>
                <Pagination.Prev onClick={() => this.setPage(pager.currentPage - 1)}
                                 disabled={pager.currentPage === 1}/>
                {pager.pages.map((page, index) =>
                    <Pagination.Item onClick={() => this.setPage(page)}
                                     key={index} active={pager.currentPage === page}>{page}</Pagination.Item>
                )}
                <Pagination.Next onClick={() => this.setPage(pager.currentPage + 1)}
                                 disabled={pager.currentPage === pager.totalPages}/>
                <Pagination.Last onClick={() => this.setPage(pager.totalPages)}
                                 disabled={pager.currentPage === pager.totalPages}/>
            </Pagination>
        );
    }
}

AppPagination.propTypes = propTypes;
AppPagination.defaultProps = defaultProps;
export default AppPagination;
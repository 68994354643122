/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.jsx";
import {identifyReferrerFromQueryString} from "../../../utils/auth";
import LoaderButton from "../../../components/utils/LoaderButton";
import connect from "react-redux/es/connect/connect";
import {authenticate} from "../../../actions/user";
import {withRouter} from "react-router-dom";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: ""
    };
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    console.log(event);
    this.setState({
      [event.controlId]: event.value
    });
  };

  handleSubmit = async event => {
    event.preventDefault();
    this.props.authenticate(this.state.email, this.state.password)
        .then(() => {
              const referrer = identifyReferrerFromQueryString(this.props);
              if (referrer) {
                this.props.history.push(referrer);
              } else {
                this.props.history.push("/admin/gateway");
              }
            }
        )
  };

  render() {
    return (
      <>
        <AuthHeader
          title="Welcome!"
          lead="Please login to your account below."
        />
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary border-0 mb-0">
                {/*<CardHeader className="bg-transparent pb-5">*/}
                  {/*<div className="text-muted text-center mt-2 mb-3">*/}
                    {/*<small>Sign in with</small>*/}
                  {/*</div>*/}
                  {/*<div className="btn-wrapper text-center">*/}
                    {/*<Button*/}
                      {/*className="btn-neutral btn-icon"*/}
                      {/*color="default"*/}
                      {/*href="#pablo"*/}
                      {/*onClick={e => e.preventDefault()}*/}
                    {/*>*/}
                      {/*<span className="btn-inner--icon mr-1">*/}
                        {/*<img*/}
                          {/*alt="..."*/}
                          {/*src={require("assets/img/icons/common/github.svg")}*/}
                        {/*/>*/}
                      {/*</span>*/}
                      {/*<span className="btn-inner--text">Github</span>*/}
                    {/*</Button>*/}
                    {/*<Button*/}
                      {/*className="btn-neutral btn-icon"*/}
                      {/*color="default"*/}
                      {/*href="#pablo"*/}
                      {/*onClick={e => e.preventDefault()}*/}
                    {/*>*/}
                      {/*<span className="btn-inner--icon mr-1">*/}
                        {/*<img*/}
                          {/*alt="..."*/}
                          {/*src={require("assets/img/icons/common/google.svg")}*/}
                        {/*/>*/}
                      {/*</span>*/}
                      {/*<span className="btn-inner--text">Google</span>*/}
                    {/*</Button>*/}
                  {/*</div>*/}
                {/*</CardHeader>*/}
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-center text-muted mb-4">
                    <small>Sign in</small>
                  </div>
                  <Form role="form">
                    <FormGroup
                      className={classnames("mb-3", {
                        focused: this.state.focusedEmail
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Email"
                          type="email"
                          value={this.state.email}
                          onChange={(e) => {this.handleChange({'controlId':'email','value':e.target.value})}}
                          onFocus={() => this.setState({ focusedEmail: true })}
                          onBlur={() => this.setState({ focusedEmail: false })}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup
                      className={classnames({
                        focused: this.state.focusedPassword
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Password"
                          type="password"
                          value={this.state.password}
                          onChange={(e) => {this.handleChange({'controlId':'password','value':e.target.value})}}
                          onFocus={() =>
                            this.setState({ focusedPassword: true })
                          }
                          onBlur={() =>
                            this.setState({ focusedPassword: false })
                          }
                        />
                      </InputGroup>
                    </FormGroup>
                    {/*<div className="custom-control custom-control-alternative custom-checkbox">*/}
                      {/*<input*/}
                        {/*className="custom-control-input"*/}
                        {/*id=" customCheckLogin"*/}
                        {/*type="checkbox"*/}
                      {/*/>*/}
                      {/*<label*/}
                        {/*className="custom-control-label"*/}
                        {/*htmlFor=" customCheckLogin"*/}
                      {/*>*/}
                        {/*<span className="text-muted">Remember me</span>*/}
                      {/*</label>*/}
                    {/*</div>*/}
                    <div className="text-center">
                      <Button className="my-4" color="info" type="button"
                              disabled={!this.validateForm()}
                              onClick={(e) => this.handleSubmit(e)}

                      >
                        Sign in
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
              {/*<Row className="mt-3">*/}
                {/*<Col xs="6">*/}
                  {/*<a*/}
                    {/*className="text-light"*/}
                    {/*href="#pablo"*/}
                    {/*onClick={e => e.preventDefault()}*/}
                  {/*>*/}
                    {/*<small>Forgot password?</small>*/}
                  {/*</a>*/}
                {/*</Col>*/}
                {/*<Col className="text-right" xs="6">*/}
                  {/*<a*/}
                    {/*className="text-light"*/}
                    {/*href="#pablo"*/}
                    {/*onClick={e => e.preventDefault()}*/}
                  {/*>*/}
                    {/*<small>Create new account</small>*/}
                  {/*</a>*/}
                {/*</Col>*/}
              {/*</Row>*/}
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    authenticating: state.auth.authenticating,
    authenticated: state.auth.authenticated,
  };
};

export default connect(mapStateToProps, {authenticate})(withRouter(Login));

import React, {Component} from "react";
import {Route, Switch, withRouter} from "react-router-dom";
import GatewayList from "../gateway/GatewayList";
import DeviceHomepage from "./DeviceHomepage";


class DevicePage extends Component {

    render() {
        return <Switch>
            <Route exact path="/admin/device" component={GatewayList}/>
            <Route path="/admin/device/:device_id" component={DeviceHomepage}/>
        </Switch>
    }
}

export default withRouter(DevicePage)
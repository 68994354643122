// GATEWAYS
export const GATEWAYS_FETCHING = "GATEWAYS_FETCHING";
export const GATEWAYS_FETCHING_SUCCESS = "GATEWAYS_FETCHING_SUCCESS";
export const GATEWAYS_FETCHING_ERROR = "GATEWAYS_FETCHING_ERROR";

export const GATEWAY_SELECTED = "GATEWAY_SELECTED";

export const GATEWAY_CREATING = "GATEWAY_CREATING";
export const GATEWAY_CREATING_SUCCESS = "GATEWAY_CREATING_SUCCESS";
export const GATEWAY_CREATING_ERROR = "GATEWAY_CREATING_ERROR";

export const GATEWAY_DELETING = "GATEWAY_DELETING";
export const GATEWAY_DELETING_SUCCESS = "GATEWAY_DELETING_SUCCESS";
export const GATEWAY_DELETING_ERROR = "GATEWAY_DELETING_ERROR";

export const GATEWAY_UPDATING = "GATEWAY_UPDATING";
export const GATEWAY_UPDATING_SUCCESS = "GATEWAY_UPDATING_SUCCESS";
export const GATEWAY_UPDATING_ERROR = "GATEWAY_UPDATING_ERROR";


// COMPANIES
export const COMPANIES_FETCHING = "COMPANIES_FETCHING";
export const COMPANIES_FETCHING_SUCCESS = "COMPANIES_FETCHING_SUCCESS";
export const COMPANIES_FETCHING_ERROR = "COMPANIES_FETCHING_ERROR";

export const COMPANY_SELECTED = "COMPANY_SELECTED";

export const COMPANY_CREATING = "COMPANY_CREATING";
export const COMPANY_CREATING_SUCCESS = "COMPANY_CREATING_SUCCESS";
export const COMPANY_CREATING_ERROR = "COMPANY_CREATING_ERROR";

// DEVICES
export const GATEWAY_FILES_FETCHING = "GATEWAY_FILES_FETCHING";
export const GATEWAY_FILES_FETCHING_SUCCESS = "GATEWAY_FILES_FETCHING_SUCCESS";
export const GATEWAY_FILES_FETCHING_ERROR = "GATEWAY_FILES_FETCHING_ERROR";

export const GATEWAY_DEVICES_FETCHING = "GATEWAY_DEVICES_FETCHING";
export const GATEWAY_DEVICES_FETCHING_SUCCESS = "GATEWAY_DEVICES_FETCHING_SUCCESS";
export const GATEWAY_DEVICES_FETCHING_ERROR = "GATEWAY_DEVICES_FETCHING_ERROR";

export const DEVICE_SELECTED = "DEVICE_SELECTED";

export const DEVICE_TIMESERIES_FETCHING = "DEVICE_TIMESERIES_FETCHING";
export const DEVICE_TIMESERIES_FETCHING_SUCCESS = "DEVICE_TIMESERIES_FETCHING_SUCCESS";
export const DEVICE_TIMESERIES_FETCHING_ERROR = "DEVICE_TIMESERIES_FETCHING_ERROR";

// REPORTS
export const AGGREGATED_TIMESERIES_FETCHING = "AGGREGATED_TIMESERIES_FETCHING";
export const AGGREGATED_TIMESERIES_FETCHING_SUCCESS = "AGGREGATED_TIMESERIES_FETCHING_SUCCESS";
export const AGGREGATED_TIMESERIES_FETCHING_ERROR = "AGGREGATED_TIMESERIES_FETCHING_ERROR";

// PROFILE
export const PROFILE_FETCHING = "PROFILE_FETCHING";
export const PROFILE_FETCHING_SUCCESS = "PROFILE_FETCHING_SUCCESS";
export const PROFILE_FETCHING_ERROR = "PROFILE_FETCHING_ERROR";

// AUTHENTICATION
export const AUTHENTICATING = "AUTHENTICATING";
export const AUTHENTICATING_SUCCESS = "AUTHENTICATING_SUCCESS";
export const AUTHENTICATING_ERROR = "AUTHENTICATING_ERROR";
export const LOGOUT = "LOGOUT";
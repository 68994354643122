import React, {Component} from "react";
import CreateCompanyForm from "./CreateCompanyForm";
import connect from "react-redux/es/connect/connect";
import SimpleHeader from "../../components/Headers/SimpleHeader";
import {Card, CardHeader, Container, Row, Table, UncontrolledTooltip} from "reactstrap";
import {selectCompany} from "../../actions/companies";


class CompanyList extends Component {

    render() {

        return (
            <>
                <SimpleHeader name="List" parentName="Companies"/>
                <Container className="mt--6" fluid>
                    <Row>
                        <div className="col">
                            <Card className="bg-default shadow">
                                <CardHeader className="bg-transparent border-0">
                                    <h3 className="text-white mb-0">Companies</h3>
                                </CardHeader>
                                <Table
                                    className="align-items-center table-dark table-flush"
                                    responsive
                                >
                                    <thead className="thead-dark">
                                    <tr>
                                        <th scope="col" className="text-left">Id</th>
                                        <th scope="col" className="text-left">Name</th>
                                        <th scope="col" className="text-left">Number of Gateways</th>
                                        <th scope="col" className="text-left">&nbsp;</th>
                                    </tr>
                                    </thead>
                                    {
                                        this.props.companies.length > 0 ? (
                                                this.props.companies.map((value, index) => {
                                                    return (
                                                        <tbody key={index}
                                                               className={'list'}>
                                                        <tr className={(value.company_id === this.props.selected.company_id) ? ' table-primary' : null}>
                                                            <th scope={'row'}
                                                            >
                                                                {value.company_id}
                                                            </th>
                                                            <td>
                                                                {value.company_name}
                                                            </td>
                                                            <td>
                                                                {value.number_of_gateways}
                                                            </td>
                                                            <td className="table-actions">
                                                                <a
                                                                    className="table-action table-action-delete"
                                                                    href="#"
                                                                    id={`tooltip-select-company-${index}`}
                                                                    onClick={() => {
                                                                        this.props.selectCompany(value)
                                                                    }}
                                                                >
                                                                    <i className="fas fa-hand-point-up"/>
                                                                </a>
                                                                <UncontrolledTooltip delay={0}
                                                                                     target={`tooltip-select-company-${index}`}>
                                                                    Select {value.name}
                                                                </UncontrolledTooltip>
                                                            </td>

                                                        </tr>

                                                        </tbody>
                                                    )
                                                })
                                            )
                                            : (
                                                <tbody>
                                                <tr>
                                                    <td colSpan="3">
                                                        No Companies
                                                    </td>
                                                </tr>
                                                </tbody>
                                            )}
                                    <tbody>
                                    <tr>
                                        <td colSpan="3" className="align-right">
                                            <CreateCompanyForm/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        companies: state.companies.items,
        loading: state.companies.loading,
        selected: state.companies.selected,
    };
};

export default connect(mapStateToProps, {selectCompany})(CompanyList);

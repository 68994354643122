import {Auth} from "aws-amplify";
import Config from "../Config";


export async function getHeaders() {
    return {'Authorization': `Bearer ${(await Auth.currentSession()).idToken.jwtToken}`};
}

function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}


export async function get(path) {
    let headers = await getHeaders();
    return fetch(Config.apiGateway.URL + path,
        {
            headers: headers,
            method: 'GET',
            // credentials: 'include'
        })
        .then(handleErrors)
        .then((res) => {
            return res.json()
        })
}

export async function post(path, body) {
    return fetch(Config.apiGateway.URL + path,
        {
            headers: await getHeaders(),
            method: 'POST',
            // credentials: 'include',
            body: JSON.stringify(body)
        })
        .then(handleErrors)
        .then((res) => {
            return res.json()
        });
}

export async function del(path) {
    return fetch(Config.apiGateway.URL + path,
        {
            method: 'DELETE',
            // credentials: 'include',
            headers: await getHeaders()
        }
    ).then(handleErrors);
}
import {AUTHENTICATING, AUTHENTICATING_ERROR, AUTHENTICATING_SUCCESS, LOGOUT} from "../constants/action-types";

const initialState = {
    authenticating: true,
    authenticated: false,
    authError: null
};


export function authReducer(state = initialState, action:any):any {

    switch (action.type) {
        case AUTHENTICATING:
            return {
                ...state,
                authenticating: true,
                authenticated: false,
                user: null
            };

        case AUTHENTICATING_SUCCESS:
            return {
                ...state,
                authenticating: false,
                authenticated: true,
            };

        case AUTHENTICATING_ERROR:
            return {
                ...state,
                authenticating: false,
                authenticated: false,
                authError: action.payload,
            };

        case LOGOUT:
            return {
                ...state,
                authenticated: false,
            };

        default:
            return state;

    }
}


import React, {Component} from "react";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {createGateway} from "../../actions/gateway";
import {connect} from "react-redux";

class CreateGatewayForm extends Component {
    constructor(props) {
        super(props);

        this.submit = this.submit.bind(this);
    }


    submit(values, {setSubmitting, resetForm}) {
        setSubmitting(true);
        this.props.createGateway(values)
            .then(() => {
                resetForm();
            })
            .finally(() => {
                setSubmitting(false);
            });
    }


    render() {
        let initialValues = {
            serial_number: '',
            location: ''
        };
        return (
            <Formik
                onSubmit={this.submit}
                initialValues={initialValues}
                validationSchema={
                    Yup.object().shape({
                        serial_number: Yup.string()
                            .required('Required'),
                        location: Yup.string()
                    })
                }>
                {props => {
                    const {
                        touched, errors, dirty, isSubmitting, handleSubmit, handleReset,
                    } = props;
                    return (
                        <Form onSubmit={handleSubmit}>
                            <div className={'row'}>
                                <div className={'col-3'}>
                                    <h3 className="text-white mb-0">Add Gateway</h3>
                                </div>
                                <div className={'col-3'}>
                                    <div className="form-group row">
                                        <label htmlFor="serial_number" style={{display: 'block'}}
                                               className={'col-4 col-form-label'}>
                                            Serial
                                        </label>
                                        <Field name="serial_number"
                                               type="text"
                                               className={'form-control col-8' + (errors.serial_number && touched.serial_number ? ' is-invalid' : '')}
                                        />
                                        <ErrorMessage name="serial_number" component="div"
                                                      className="invalid-feedback"/>

                                    </div>
                                </div>
                                <div className={'col-3'}>
                                    <div className="form-group row">
                                        <label htmlFor="location" style={{display: 'block'}}
                                               className="col-4 col-form-label">
                                            Location
                                        </label>

                                        <Field name="location"
                                               type="text"
                                               className={'form-control col-8' + (errors.location && touched.location ? ' is-invalid' : '')}
                                        />
                                        <ErrorMessage name="location" component="div"
                                                      className="invalid-feedback"/>

                                    </div>
                                </div>
                                <div className={'col-3'}>
                                    <div className={'form-group row'}>
                                        <div className={'col-12'}>
                                            <button type="submit" disabled={isSubmitting}
                                                    className="btn btn-primary mr-2">
                                                Add Gateway
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </Form>
                    )
                }}

            </Formik>
        )
    }
}

export default connect(null, {createGateway})(CreateGatewayForm);
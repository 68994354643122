import {get, post} from "./index";
import {
    COMPANIES_FETCHING,
    COMPANIES_FETCHING_SUCCESS,
    COMPANY_CREATING,
    COMPANY_CREATING_SUCCESS,
    COMPANY_SELECTED
} from "../constants/action-types";


function setCompanies(companies) {
    return {type: COMPANIES_FETCHING_SUCCESS, payload: companies};
}

function setSelectedCompany(company) {
    return {type: COMPANY_SELECTED, payload: company};

}

function notifyFetchingCompanies() {
    return {type: COMPANIES_FETCHING}
}

function sortCompanies(companies) {
    return companies.sort((a, b) => {
        if (a.company_id < b.company_id) return -1;
        if (a.company_id > b.company_id) return 1;
        return 0;
    });
}


export function loadCompanies(selectDefault = false, selectCompanyId) {

    return (dispatch) => {

        console.log(selectDefault, selectCompanyId);
        dispatch(notifyFetchingCompanies());
        return new Promise((resolve, reject) => {
            {
                get('/company')
                    .then(
                        (data) => {
                            let companies = sortCompanies(data.companies);
                            if (selectDefault && companies.length > 1) {
                                dispatch(selectCompany(companies[0]));
                            } else if (selectCompanyId) {
                                dispatch(selectCompanyFromId(selectCompanyId, companies));
                            }
                            dispatch(setCompanies(companies));
                            resolve();
                        }
                    )
                    .catch((err) => {
                        console.log('error');
                        dispatch(setCompanies([]));
                        resolve();
                    });
            }
        });
    }
}

export function selectCompanyFromId(company_id, companies) {
    return (dispatch, getState) => {
        console.log(companies);
        let company = companies.find((obj) => {
            return obj.company_id === company_id
        });
        return dispatch(selectCompany(company));
    }
}

export function selectCompany(company) {
    return function (dispatch) {
        dispatch(setSelectedCompany(company));
    }
}

// export function selectGateway(gateway_id) {
//     return async function (dispatch, getState) {
//         if (getState().gateways.selected && getState().gateways.selected.gateway_id === gateway_id) {
//             return
//         }
//         let gateways = getState().gateways.items;
//         if (gateways.length === 0) {
//             await dispatch(loadGateways());
//             gateways = getState().gateways.items;
//         }
//         const gateway = gateways.find((el) => {
//             return el.gateway_id === gateway_id
//         });
//         if (gateway) {
//             dispatch({type: GATEWAY_SELECTED, payload: gateway});
//             dispatch(loadGatewayDevices(gateway_id));
//             dispatch(loadGatewayFiles(gateway_id));
//         }
//     }
// }
//
export function createCompany(company) {
    return async function (dispatch) {
        dispatch({type: COMPANY_CREATING});
        return post('/company', company).then(
            (data) => {
                dispatch({type: COMPANY_CREATING_SUCCESS, payload: data});
            }
        )
    }
}


import {
    COMPANIES_FETCHING,
    COMPANIES_FETCHING_SUCCESS,
    COMPANY_CREATING,
    COMPANY_CREATING_SUCCESS, COMPANY_SELECTED,
    LOGOUT
} from "../constants/action-types";

const initialState = {
    items: [],
    loading: false,
    selected: null
};


export function companiesReducer(state = initialState, action: any): any {

    switch (action.type) {
        case COMPANIES_FETCHING:
            return {
                ...state,
                loading: true
            };
        case COMPANIES_FETCHING_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.payload
            };

        case COMPANY_CREATING:
            return {
                ...state,
                loading: true
            };

        case COMPANY_CREATING_SUCCESS:
            return {
                ...state,
                loading: false,
                items: [...state.items, action.payload]
            };

        case COMPANY_SELECTED:
            return {
                ...state,
                selected: action.payload
            };

        // case GATEWAY_DEVICES_FETCHING:
        //     return {
        //         ...state,
        //         loading: true
        //     };
        //
        // case GATEWAY_DEVICES_FETCHING_SUCCESS:
        //     return {
        //         ...state,
        //         loading: false,
        //         items: action.payload
        //     };
        //
        // case DEVICE_SELECTED:
        //     return {
        //         ...state,
        //         selected: action.payload
        //     };

        case LOGOUT:
            return {
                ...state,
                items: [],
                loading: false,
                selected: null
            };

        default: {
            return state;
        }
    }
}
import React, {Component} from "react";
import Pagination from "../../components/Pagination";
import connect from "react-redux/es/connect/connect";
import {Table} from "reactstrap";
import CompanyNavLink from "../../components/navigation/CompanyNavLink";

class GatewayDevicelist extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pageOfDevices: []
        };

        this.onChangePage = this.onChangePage.bind(this);
    }

    onChangePage(pageOfDevices) {
        this.setState({pageOfDevices: pageOfDevices});
    }


    render() {
        console.log(this.state);
        return (
            <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                    <tr>
                        <th className="text-left">Device ID</th>
                        <th className="text-left">Serial Number</th>
                        <th className="text-left">Type</th>
                        <th className="text-left">Manufacturer</th>
                        <th className="text-left">&nbsp;</th>
                    </tr>
                    </thead>

                    {this.state.pageOfDevices.map((value, index) => {
                        return (
                            <tbody key={index} className='list'>
                            <tr>
                                <td className="text-left">
                                    <CompanyNavLink to={`/admin/device/${value.device_id}`}>
                                        {value.device_id}
                                    </CompanyNavLink>
                                </td>
                                <td className="text-left">{value.serial_number}</td>
                                <td className="text-left">{value.device_type}</td>
                                <td className="text-left">{value.manufacturer}</td>
                                <td className="text-left">&nbsp;</td>
                                {/*<td>{JSON.stringify(value)}</td>*/}
                            </tr>
                            </tbody>
                        )
                    })
                    }
                    <tbody>
                    <tr>
                        <td colSpan="5">
                            <Pagination items={this.props.devices} onChangePage={this.onChangePage}/>
                        </td>
                    </tr>
                    </tbody>
            </Table>
        )
    }
}

const mapStateToProps = state => {
    return {
        gateway: state.gateways.selected,
        loading: state.devices.loading,
        devices: state.devices.items
    };
};

export default connect(mapStateToProps, {})(GatewayDevicelist);



import React from "react";
import {NavLink} from "reactstrap";
import {connect} from "react-redux";
import {Link, NavLink as NavLinkRRD} from "react-router-dom";

function CompanyNavLink(props: any) {

    if (props.company) {
        props.ownProps.to = props.ownProps.to + `#company=${props.company.company_id}`
    }
    if (!props.ownProps.tag) {
        props.ownProps.tag = NavLinkRRD
    }

    return (
            <NavLink {...props.ownProps}>
                {props.ownProps.children}
            </NavLink>
    )
}

const mapStateToProps = function(state:any, ownProps: any) {
    return {
        company: state.companies.selected,
        ownProps: ownProps
    }
};

export default connect(mapStateToProps)(CompanyNavLink);
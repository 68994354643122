import React, {Component} from 'react';
import GatewayFilelist from "./GatewayFilelist";
import GatewayDevicelist from "./GatewayDevicelist";
import {selectGateway} from "../../actions/gateway";
import GatewayReport from "./GatewayReport";
import {loadAggegatedTimeSeriesData} from "../../actions/device";
import {Redirect, Switch, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import AppliedRoute from "../../components/AppliedRoute";
import LinkButton from "../../components/utils/LinkButton.tsx";
import SimpleHeader from "components/Headers/SimpleHeader";
import {Card, CardBody, CardHeader, Container, Row} from "reactstrap";
import RouteAwareRedirect from "../../components/navigation/RouteAwareRedirect";


class GatewayHomepage extends Component {

    componentWillMount = async () => {
        await this.props.selectGateway(this.props.match.params.gateway_id);
    };


    render() {
        console.log(this.props);
        let gateway_id = this.props.match.params.gateway_id;
        let gateway_id_short = gateway_id.substr(gateway_id.length - 8);

        return (
            this.props.gateway ?
                <>
                    <SimpleHeader name={`Gateway ${gateway_id_short}`} parentName="Gateways"/>
                    <Container className="mt--6" fluid>
                        <Row>
                            <div className="col">
                                <Card>
                                    <CardHeader className="border-0">
                                        <h3 className="mb-0">Gateway {gateway_id}</h3>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="btn-group">

                                            {/*<Row>*/}
                                            {/*<div className={'col'}>*/}
                                            {/*<ButtonToolbar>*/}
                                            <LinkButton to={`/admin/gateway/${gateway_id}/files`}
                                                        company={true}>Files</LinkButton>
                                            <LinkButton
                                                to={`/admin/gateway/${gateway_id}/devices`}
                                                company={true}>Devices</LinkButton>
                                            <LinkButton to={`/admin/gateway/${gateway_id}/report`}
                                                        company={true}>Data</LinkButton>
                                            {/*</ButtonToolbar>*/}
                                            {/*</div>*/}
                                            {/*</Row>*/}
                                        </div>
                                    </CardBody>
                                    <Switch>
                                        <AppliedRoute path="/admin/gateway/:gateway_id/devices" exact
                                                      component={GatewayDevicelist}
                                        />
                                        <AppliedRoute path="/admin/gateway/:gateway_id/files" exact
                                                      component={GatewayFilelist}
                                        />
                                        <AppliedRoute path="/admin/gateway/:gateway_id/report" exact
                                                      component={GatewayReport}
                                                      props={{gateway_id: gateway_id}}
                                        />
                                        <Redirect exact from="/admin/gateway/:gateway_id"
                                                  to={{pathname:`/admin/gateway/${gateway_id}/files`,
                                                      hash: this.props.location.hash

                                                  }}/>
                                    </Switch>
                                </Card>
                            </div>
                        </Row>
                    </Container>
                </>
                :
                null
        )
    }
}

const mapStateToProps = state => {
    return {
        gateway: state.gateways.selected,
        loading: (state.devices.loading || state.gatewayFiles.loading),
        files: state.gatewayFiles.items,
        devices: state.devices.items
    };
};

export default connect(mapStateToProps, {selectGateway, loadAggegatedTimeSeriesData})(withRouter(GatewayHomepage));



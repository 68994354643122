import {
    COMPANY_SELECTED,
    GATEWAY_CREATING,
    GATEWAY_CREATING_SUCCESS,
    GATEWAY_SELECTED,
    GATEWAYS_FETCHING,
    GATEWAYS_FETCHING_SUCCESS,
    LOGOUT
} from "../constants/action-types";

const initialState = {
    items: [],
    loading: false,
    selected: null
};

export function gatewaysReducer(state = initialState, action:any):any {

    switch (action.type) {
        case GATEWAYS_FETCHING:
            return {
                ...state,
                loading: true
            };

        case GATEWAYS_FETCHING_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.payload
            };


        case GATEWAY_CREATING:
            return {
                ...state,
                loading: true
            };

        case GATEWAY_CREATING_SUCCESS:
            return {
                ...state,
                loading: false,
                items: [...state.items, action.payload]
            };


        case GATEWAY_SELECTED:
            return {
                ...state,
                selected: action.payload
            };

        case COMPANY_SELECTED:
        case LOGOUT:
            return {
                ...state,
                items: [],
                loading: false,
                selected: null
            };

        default:
            return state;

    }
}
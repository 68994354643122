import {
    COMPANY_SELECTED,
    GATEWAY_FILES_FETCHING,
    GATEWAY_FILES_FETCHING_SUCCESS,
    LOGOUT
} from "../constants/action-types";

const initialState = {
    items: [],
    loading: false,
};


export function gatewayFilesReducer(state = initialState, action:any):any {

    switch (action.type) {

        case GATEWAY_FILES_FETCHING:
            return {
                ...state,
                loading: true
            };

        case GATEWAY_FILES_FETCHING_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.payload
            };


        case COMPANY_SELECTED:
        case LOGOUT:
            return {
                ...state,
                items: [],
                loading: false,
            };

        default:
            return state;

    }
}
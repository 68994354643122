import React, {Component} from 'react';
import './App.scss';
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {silentAuth} from "./actions/user";
import PageSpinner from "./components/utils/PageSpinner";
import {identifyReferrer} from "./utils/auth";
import NotFound from "./components/NotFound";

import AdminLayout from "layouts/Admin.jsx";
import AuthLayout from "layouts/Auth.jsx";
import {selectCompanyFromId} from "./actions/companies";


function parseHash(hash) {
    hash = hash.substring(1);
    let params = {};
    hash.split('&').forEach(hk => {
        console.log(hk);
        let temp = hk.split('=');
        params[temp[0]] = temp[1]
    });
    return params;
}

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            initialized: false
        }
    }

    _selectCompanyFromLocationHash() {
        if (this.props.location.hash) {
            let params = parseHash(this.props.location.hash);
            if (params.company) {
                console.log('select company ', params.company);
                return params.company;
            }
        }
        return null;
    }

    async _silentAuth(company_id = null) {
        return this.props.silentAuth(company_id)
            .catch(
                () => {
                    const referer = identifyReferrer(this.props);
                    if (referer) {
                        this.props.history.push(`/auth/login?referrer=${referer}`)
                    } else {
                        this.props.history.push(`/auth/login`)
                    }
                })
            .finally(
                () => {
                    this.setState({initialized: true});
                });
    }

    async componentDidMount() {
        let company_id = this._selectCompanyFromLocationHash();
        await this._silentAuth(company_id);
    };

    render() {

        return <>
            <PageSpinner/>

            {
                this.state.initialized ? (
                    (!(this.props.authenticating || this.props.userLoading)) ? (
                            <Switch>
                                <Route path="/admin" render={props => <AdminLayout {...props} />}/>
                                <Route path="/auth" render={props => <AuthLayout {...props} />}/>
                                {/*<Route path="/" render={props => <IndexView {...props} />}/>*/}
                                <Redirect from="*" to="/auth/login"/>
                                {/*<AppliedRoute path="/login" exact component={Login}/>*/}
                                {/*<AppliedRoute path="/company" exact component={CompanyHomepage}/>*/}
                                {/*<AppliedRoute path="/company/list" exact component={CompanyListContainer}/>*/}
                                {/*<AppliedRoute path="/gateway/:gateway_id"  component={GatewayHomepage}/>*/}
                                {/*<AppliedRoute path="/device/:device_id"  component={DeviceHomepage}/>*/}
                                {/*<Redirect exact from="/" to="/company"/>*/}
                                { /* Finally, catch all unmatched routes */}
                                <Route component={NotFound}/>
                            </Switch>
                        )
                        :
                        null
                ) : null
            }
        </>
    };
}

const mapStateToProps = state => {
    return {
        authenticating: state.auth.authenticating,
        userLoading: state.profile.loading
    }
};


export default connect(mapStateToProps, {silentAuth, selectCompanyFromId})(withRouter(App));

import {
    AGGREGATED_TIMESERIES_FETCHING,
    AGGREGATED_TIMESERIES_FETCHING_SUCCESS, COMPANY_SELECTED,
    LOGOUT
} from "../constants/action-types";

const initialState: AggregatedTimeSeriesState = {
    data: null,
    loading: false,
};

interface AggregatedTimeSeriesState {
    data: null | [number],
    loading: boolean
}

export function aggregatedTimeSeriesReducer(state: AggregatedTimeSeriesState = initialState, action: any): AggregatedTimeSeriesState {

    switch (action.type) {
        case AGGREGATED_TIMESERIES_FETCHING:
            return {
                ...state,
                loading: true,
                data: null,
            };


        case AGGREGATED_TIMESERIES_FETCHING_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
            };

        case COMPANY_SELECTED:
        case LOGOUT :
            return {
                ...state,
                data: null,
                loading: false,
            };

        default:
            return state;

    }
}
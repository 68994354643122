import React from "react";
import {Card, CardBody, Modal, Table, UncontrolledTooltip} from "reactstrap";
import NullHighLightText from "../../components/NullHighLightText";


export default class GatewayDetailButton extends React.Component {
    state = {
        modal: false
    };


    toggleModal = () => {
        this.setState({modal: !this.state['modal']});
    };

    render() {
        let gateway = this.props.gateway;

        return <>
            <a
                className="table-action"
                href="#"
                id={`tooltip-gateway-detail-${gateway.gateway_id}`}
                onClick={e => this.toggleModal()}
            >
                <i className="fas fa-info-circle"/>
            </a>
            <UncontrolledTooltip delay={0}
                                 target={`tooltip-gateway-detail-${gateway.gateway_id}`}>
                View gateway details
            </UncontrolledTooltip>
            <Modal
                className="modal-dialog-centered"
                size="lg"
                isOpen={this.state.modal}
                toggle={() => this.toggleModal()}
            >
                <div className="modal-body p-0">
                    <Card className="bg-secondary border-0 mb-0">
                        <CardBody className="px-lg-5 py-lg-5">
                            <div className="text-center text-muted mb-4">
                                <h3>Gateway Details</h3>
                            </div>
                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                <tr>
                                    <th>Property</th>
                                    <th>Value</th>
                                </tr>
                                </thead>
                                <tbody className={'list'}>
                                <tr>
                                    <th scope="row">Gateway ID</th>
                                    <td>{gateway.gateway_id}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Location</th>
                                    <td>{gateway.location}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Serial Number</th>
                                    <td>{gateway.serial_number}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Last contact</th>
                                    <td><NullHighLightText value={gateway.last_contact} format={'date'}/></td>
                                </tr>
                                <tr>
                                    <th scope="row">MAC Address</th>
                                    <td>{gateway.mac_address}</td>
                                </tr>
                                <tr>
                                    <th scope="row">IP Address</th>
                                    <td>{gateway.ip_address}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Model</th>
                                    <td>{gateway.model}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Firmware</th>
                                    <td>{gateway.firmware_version}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Number of devices</th>
                                    <td>{gateway.number_of_devices}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Post URL</th>
                                    <td>https://api.{window.location.hostname}/integrations/elvaco/{gateway.company_id}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Password</th>
                                    <td>{gateway.credentials.password}</td>
                                </tr>


                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </div>
            </Modal>

        </>
    }

}



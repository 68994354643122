import {
    COMPANY_SELECTED,
    DEVICE_SELECTED,
    GATEWAY_DEVICES_FETCHING,
    GATEWAY_DEVICES_FETCHING_SUCCESS,
    LOGOUT
} from "../constants/action-types";

const initialState = {
    items: [],
    loading: false,
    selected: null
};


export function devicesReducer(state = initialState, action:any):any {

    switch (action.type) {

        case GATEWAY_DEVICES_FETCHING:
            return {
                ...state,
                loading: true
            };


        case GATEWAY_DEVICES_FETCHING_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.payload
            };


        case DEVICE_SELECTED:
            return {
                ...state,
                selected: action.payload
            };

        case COMPANY_SELECTED:
        case LOGOUT:
            return {
                ...state,
                items: [],
                loading: false,
                selected: null
            };

        default:
            return state;

    }
}
import React, {Component} from "react";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {connect} from "react-redux";
import {createCompany} from "../../actions/companies";

class CreateCompanyForm extends Component {
    constructor(props) {
        super(props);

        this.submit = this.submit.bind(this);
    }


    submit(values, {setSubmitting, resetForm}) {
        setSubmitting(true);
        let newCompany = {
            new_company: {
                name: values.company_name
            },
            new_user: {
                email_address: values.email_address,
                password: values.password
            }
        };
        this.props.createCompany(newCompany)
            .then(() => {
                resetForm();
            })
            .finally(() => {
                setSubmitting(false);
            });
    }


    render() {
        let initialValues = {
            company_name: '',
            email_address: '',
            password: '',
        };
        return (
            <Formik
                onSubmit={this.submit}
                initialValues={initialValues}
                validationSchema={
                    Yup.object().shape({
                        company_name: Yup
                            .string()
                            .required('Required'),
                        email_address: Yup
                            .string()
                            .email()
                            .required('Required'),
                        password: Yup
                            .string()
                            .required('Required')
                            .min(8, 'Password is too short - should be 8 chars minimum.')
                            .matches(/[a-zA-Z0-9]/, 'Password can only letters a-z, A-Z or numbers 0-9.')
                    })
                }>
                {props => {
                    const {
                        touched, errors, dirty, isSubmitting, handleSubmit, handleReset,
                    } = props;
                    return (
                        <Form onSubmit={handleSubmit}>
                            <div className={'row'}>
                                <div className={'col-3'}>
                                    <h3 className="text-white mb-0">Add Company</h3>
                                </div>
                            </div>
                            <div className={'row'}>
                                <div className={'col-3'}>
                                    <div className="form-group row">
                                        <label htmlFor="company_name" style={{display: 'block'}}
                                               className={'col-4 col-form-label'}>
                                            Name
                                        </label>
                                        <Field name="company_name"
                                               type="text"
                                               className={'form-control col-8' + (errors.company_name && touched.company_name ? ' is-invalid' : '')}
                                        />
                                        <ErrorMessage name="company_name" component="div"
                                                      className="invalid-feedback"/>
                                    </div>
                                </div>
                                <div className={'col-3'}>
                                    <div className="form-group row">
                                        <label htmlFor="email_address" style={{display: 'block'}}
                                               className={'col-4 col-form-label'}>
                                            Email
                                        </label>
                                        <Field name="email_address"
                                               type="text"
                                               className={'form-control col-8' + (errors.email_address && touched.email_address ? ' is-invalid' : '')}
                                        />
                                        <ErrorMessage name="email_address" component="div"
                                                      className="invalid-feedback"/>
                                    </div>
                                </div>
                                <div className={'col-3'}>
                                    <div className="form-group row">
                                        <label htmlFor="password" style={{display: 'block'}}
                                               className={'col-4 col-form-label'}>
                                            Password
                                        </label>
                                        <Field name="password"
                                               type="password"
                                               className={'form-control col-8' + (errors.password && touched.password ? ' is-invalid' : '')}
                                        />
                                        <ErrorMessage name="password" component="div"
                                                      className="invalid-feedback"/>
                                    </div>
                                </div>
                                <div className={'col-3'}>
                                    <div className={'form-group row'}>
                                        <div className={'col-12'}>
                                            <button type="submit" disabled={isSubmitting}
                                                    className="btn btn-primary mr-2">
                                                Add Company
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </Form>
                    )
                }}

            </Formik>
        )
    }
}

export default connect(null, {createCompany})(CreateCompanyForm);